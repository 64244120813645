import { Metadata } from '@redwoodjs/web'

import MemberManagementCell from 'src/components/MemberManagementCell'
import NavTabs from 'src/components/NavTabs/NavTabs'
import PageHeader from 'src/components/PageHeader/PageHeader'

const SettingsUsersPage = () => {
  const tabs = [
    { name: 'Members', href: '#', current: true },
    {
      name: 'Groups',
      href: '/app/settings/user-management/groups',
      current: false,
    },
  ]
  return (
    <>
      <Metadata
        title="Member Management"
        description="Member Management page"
      />
      <PageHeader
        title="Member Management"
        parentDataTestId="member-management-page"
      ></PageHeader>
      <NavTabs tabs={tabs} />
      <MemberManagementCell />
    </>
  )
}

export default SettingsUsersPage
